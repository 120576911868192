<template>
    <div class="-mt-4">
        <div class="w-full">
            <div class="pb-6 md:flex md:items-center md:justify-between">
                <div class="flex-1 min-w-0">
                    <div class="flex items-center">
                        <div>
                            <div class="flex items-center">
                                <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900 dark:text-gray-200 sm:leading-9 sm:truncate">Sveiks, {{user.name}}</h1>
                            </div>

                            <dl class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                <dd class="flex items-center text-sm text-gray-500 dark:text-gray-400 font-medium capitalize sm:mr-6">
                                    <span class="mr-1">Šodien </span> <span class="lowercase"> {{getFullDate()}}</span>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import moment from "moment"

export default {
    name: "Header",
    computed: {
        ...mapGetters({
            user: "user"
        })
    },
    methods: {
        getFullDate() {
            moment.locale('lv')
            return " " + moment().format("dddd DD. MMMM YYYY.") + " gads"
        }
    }
}
</script>

<style>

</style>